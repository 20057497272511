import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion/dist/framer-motion';
import { useInView } from 'react-intersection-observer';
import PText from '../components/PText';
import AboutImg from '../assets/images/about-page-img.png';
import AboutInfoItem from '../components/AboutInfoItem';
import ContactBanner from '../components/ContactBanner';

const AboutPageStyles = styled.div`
  padding: 20rem 0 10rem 0;
  background-color: black;
  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .left {
    flex: 3;
  }
  .right {
    flex: 2;
  }
  .about__subheading {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    span {
      background-color: rgba(51, 102, 255, 0.3);
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  .about__heading {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }
  .about__info {
    margin-bottom: 4rem;
    .para {
      max-width: 100%;
    }
  }
  .right {
    img {
      border: 2px solid white;
      margin-bottom: 25rem;
    }
  }
  .about__info__items {
    margin-top: 15rem;
  }
  .about__info__item {
    margin-bottom: 10rem;
  }
  .about__info__heading {
    font-size: 3.6rem;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 768px) {
    padding: 10rem 0;
    .top-section {
      flex-direction: column;
      gap: 5rem;
    }
    .about__subheading {
      font-size: 1.8rem;
    }
    .about__heading {
      font-size: 2.8rem;
    }
    .about__info__heading {
      font-size: 3rem;
    }
  }

  .button-clone {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: rgba(0, 32, 62, 0.6);
    border: 2px solid white;
    margin-top: 2rem;
    border-radius: 8px;
    display: inline-block;
    color: white;
    cursor: pointer;
    margin: 1.5rem;
    @media only screen and (max-width: 768px) {
      .button-clone {
        font-size: 1.8rem;
      }
    }
  }
`;

export default function About() {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    // console.log('use effect hook , inView = ', inView);
    if (inView) {
      animation.start({
        y: -10,
        transition: {
          type: 'spring',
          duration: 3,
          bounce: 0.2,
        },
      });
    }
    if (!inView) {
      animation.start({ y: '10vw' });
    }
  });
  return (
    <>
      <AboutPageStyles>
        <div className="container">
          <div className="top-section">
            <div className="left">
              <p className="about__subheading">
                Hi, I am <span>Batuhan Satilmis</span>
              </p>
              <h2 className="about__heading">Front End Developer</h2>
              <div className="about__info">
                <PText>
                  I am from Istanbul, Turkey. I have been interested in
                  computers since my childhood, so I completed my university
                  education as Computer Engineering.
                  <br /> <br />
                  The idea of developing websites started with writing "hello
                  world" like everyone else. The "Introduction to Web
                  Technologies" course that I learned in university education
                  led me to this path. Although it was difficult coding at first
                  but I enjoyed designing a website and observing it over time,
                  so I wanted to reflect this situation on my business life.
                  <br />
                  <br />I don't want to make cliché sentences like ; "My vision
                  is to make the world a better place". My real goal is to
                  design professional websites that will make a good impression
                  on people.
                  <br /> <br />
                  I am working as a Front End developer at the moment, but I am
                  also interested in Back End and Mobile Application
                  development. Even if I do not have much knowledge about these
                  technologies for now, I am making a career plan to become a
                  Full Stack Developer in the future.
                  <br /> <br />
                  Finally, I think that I am a harmonious person who can
                  communicate well with people, is prone to teamwork.
                </PText>
              </div>

              <button
                type="button"
                className="button-clone"
                onClick={() =>
                  window.open(
                    'https://drive.google.com/file/d/1YeXdQGilkTZXdOUZ0eoYD58VAsgncX_X/view?usp=sharing',
                    '_blank'
                  )
                }
              >
                Download CV
              </button>
            </div>
            <div className="right" ref={ref}>
              <motion.div animate={animation}>
                <img src={AboutImg} alt="me" />
              </motion.div>
            </div>
          </div>
          <div className="about__info__items">
            <div className="about__info__item">
              <h1 className="about__info__heading">Education</h1>

              <AboutInfoItem
                title="University"
                items={['Istanbul Aydin University : Computer Engineering']}
              />
              <AboutInfoItem
                title="High School"
                items={['Sinav Egitim Kurumlari : Science Department']}
              />
            </div>
            <div className="about__info__item">
              <h1 className="about__info__heading">My Skills</h1>

              <AboutInfoItem
                title="FrontEnd"
                items={['HTML', 'CSS', 'Bootstrap', 'Saas']}
              />
              <AboutInfoItem title=" " items={['Javascript', 'React']} />

              <AboutInfoItem
                title="BackEnd"
                items={['Node', 'Express', 'Next']}
              />
              <AboutInfoItem
                title="Other"
                items={['Typescript', 'MongoDB', 'React Native', 'Swift']}
              />
              <AboutInfoItem title=" " items={['Java', 'C', 'MySQL']} />
            </div>
            <div className="about__info__item">
              <h1 className="about__info__heading">Experiences</h1>

              <AboutInfoItem
                title="2021"
                items={[
                  'Intern Front End Developer at Arvato Supply Chain Solutions Turkey',
                ]}
              />
              <AboutInfoItem
                title="2022"
                items={['IT Speacialist at Yenasoft Yazilim']}
              />
            </div>
          </div>
        </div>
        <ContactBanner />
      </AboutPageStyles>
    </>
  );
}
