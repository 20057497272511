import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProjectImg from '../assets/images/projectImg.png';

const ProjectItemStyles = styled.div`
  .projectItem__img {
    width: 100%;
    height: 400px;
    border-radius: 12px;
    overflow: hidden;
    display: inline-block;
    border: 3px solid rgba(0, 32, 63, 0.3);
 

    img {
      height: 100%;
      display: flex;
    justify-content: center;
    }
  }
  .button-up {
    display: flex;
    justify-content: center;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    font-size: 2rem;
    margin: 10px;
  }
  .button-clone {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    background-color: rgba(0,32,62,0.6);
    border: 2px solid white;
    margin-top: 2rem;
    border-radius: 6px;
    padding: 5px;
    display: inline-block;
    color: white;
    cursor: pointer;
    margin: 1.5rem;
    @media only screen and (max-width: 768px) {
      .button-clone {
        font-size: 1.8rem;
      }
    }
}
    
  }
  .projectItem__info {
    margin-top: 1rem;
    background-color: rgba(0, 32, 62, 0.3);
    padding: 1rem;
    border-radius: 12px;
  }
  .projectItem__title {
    font-size: 2.2rem;
  }
  .projectItem__desc {
    font-size: 1.6rem;
    font-family: 'RobotoMono Regular';
    margin-top: 1rem;
  }
  @media only screen and (max-width: 768px) {
    .projectItem__img {
      height: 350px;
    }
  }
`;

export default function ProjectItem({
  img = ProjectImg,
  title = 'Project Name',
  desc = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  link = 'https://github.com/BatuhanSatilmis',
  live = '',
}) {
  return (
    <ProjectItemStyles>
      <Link to="/projects" className="projectItem__img">
        <img src={img} alt="project img" />
      </Link>
      <div className="projectItem__info">
        <Link to="">
          <h3 className="projectItem__title">{title}</h3>
        </Link>
        <p className="projectItem__desc">{desc}</p>
        <div className="button-up">
          <button
            type="button"
            className="button-clone"
            onClick={() => window.open(link, '_blank')}
          >
            Github
          </button>

          <button type="button" className="button-clone">
            <a href={live}>Live</a>
          </button>
        </div>
      </div>
    </ProjectItemStyles>
  );
}
