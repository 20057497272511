import React from 'react';
import styled from 'styled-components';
import FooterCol from './FooterCol';
import PText from './PText';

const FooterStyle = styled.div`
  background-color: rgba(0, 0, 0);

  box-shadow: 0 3px 8px 0 rgba(0, 32, 63);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  padding-top: 10rem;

  .container {
    display: flex;
    gap: 3rem;
  }
  .footer__col1 {
    flex: 2;
  }
  .footer__col2,
  .footer__col3,
  .footer__col4 {
    flex: 1;
  }
  .footer__col1__title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  .copyright {
    background-color: rgba(0, 32, 62, 0.3);
    text-align: left;
    padding: 1rem 0;
    margin-top: 5rem;
    .para {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      gap: 0rem;
      & > div {
        margin-top: 5rem;
      }
    }
    .footer__col1 .para {
      max-width: 100%;
    }
    .copyright {
      .container {
        div {
          margin-top: 0;
        }
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyle>
      <div className="footer-up">
        <div className="container">
          <div className="footer__col1">
            <h1 className="footer__col1__title">Batuhan</h1>
            <PText>
              A web developer from Istanbul. Always wanted to build websites
              with distinctive styles and high performance rates.
            </PText>
          </div>
          <div className="footer__col2">
            <FooterCol
              heading="Important Links"
              links={[
                {
                  title: 'Home',
                  path: '/',
                  type: 'Link',
                },
                {
                  type: 'Link',
                  title: 'About',
                  path: '/about',
                },
                {
                  type: 'Link',
                  title: 'Projects',
                  path: '/projects',
                },
                {
                  type: 'Link',
                  title: 'Contact',
                  path: '/contact',
                },
              ]}
            />
          </div>
          <div className="footer__col3">
            <FooterCol
              heading="Contact Info"
              links={[
                {
                  title: '+90',
                  path: 'tel:+90',
                },
                {
                  title: 'admin@batuhansatilmis.com',
                  path: 'mailto:admin@batuhansatilmis.com',
                },
                {
                  title: 'Bakirkoy, Istanbul, Turkey',
                  path: 'http://google.com/maps',
                },
              ]}
            />
          </div>
          <div className="footer__col4">
            <FooterCol
              heading="social Links"
              links={[
                {
                  title: 'Linkedin',
                  path: 'http://linkedin.com/in/batuhan-satilmis-29063814a',
                },
                {
                  title: 'Github',
                  path: 'https://github.com/BatuhanSatilmis',
                },
                {
                  title: 'Instagram',
                  path: 'https://www.instagram.com/batuhanstlmis',
                },
              ]}
            />
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <PText>© 2022 - Batuhan Satilmis | Developer</PText>
          </div>
        </div>
      </div>
    </FooterStyle>
  );
}
