const testimonials = [
  {
    id: 1,
    name: 'Arvato Supply Chain Solutions Turkey',
    title: 'Internship',
    org: 'Web Developer',
    desc:
      'In 2021,I worked as an intern at Arvato Supply Chain Solutions company, learned about databases and software industry, I built a simple online sales site using servlets',
  },
  {
    id: 2,
    name: 'YenaSoft Yazilim',
    title: 'Internship',
    org: 'IT Specialist',
    desc:
      'in 2022, I worked as an IT specialist trainee, maintaining the data center and thecompany`s information system, managing components such as computer networks, system, physical hardware, software, communication and security,and ensuring the active operation of services',
  },
  {
    id: 3,
    name: 'Arvato Supply Chain Solutions Turkey',
    title: 'Internship',
    org: 'Web Developer',
    desc:
      'In 2021,I worked as an intern at Arvato Supply Chain Solutions company, learned about databases and software industry, I built a simple online sales site using servlets',
  },
  {
    id: 4,
    name: 'YenaSoft Yazilim',
    title: 'Internship',
    org: 'IT Specialist',
    desc:
      'in 2022, I worked as an IT specialist trainee, maintaining the data center and thecompany`s information system, managing components such as computer networks, system, physical hardware, software, communication and security,and ensuring the active operation of services',
  },
];
export default testimonials;
