import React from 'react';
import styled from 'styled-components';
import { FaNodeJs } from 'react-icons/fa';
import { TbBrandJavascript, TbBrandReactNative } from 'react-icons/tb';

import SectionTitle from './SectionTitle';
import ServicesSectionItem from './ServicesSectionItem';

const ServicesItemsStyles = styled.div`
  background-color: black;

  padding: 10rem 0;
  .services__allItems {
    display: flex;
    gap: 10rem;
    justify-content: space-between;
    margin-top: 5rem;
  }
  @media only screen and (max-width: 768px) {
    .services__allItems {
      flex-direction: column;
      max-width: 350px;
      margin: 0 auto;
      margin-top: 5rem;
      gap: 5rem;
    }
  }
`;

export default function ServicesSection() {
  return (
    <ServicesItemsStyles>
      <div className="container">
        <SectionTitle subheading="" heading="My Skills" />

        <div className="services__allItems">
          <ServicesSectionItem
            icon={<TbBrandJavascript className="icon" />}
            title="Front-End Development"
            desc="CSS | Bootstrap | Sass | Javascript | React"
          />

          <ServicesSectionItem
            icon={<FaNodeJs />}
            title="Back-End Development"
            desc="Node | Express"
          />

          <ServicesSectionItem
            icon={<TbBrandReactNative />}
            title="Mobile Application Development"
            desc="React Native | Swift"
          />
        </div>
      </div>
    </ServicesItemsStyles>
  );
}
