import { v4 as uuidv4 } from 'uuid';
import millionare from '../images/millionare.jpg';
import mernstackmapapp from '../images/mern-stack-map-app.jpg';
import eCommerce from '../images/e-commerce.jpg';
import moviedb from '../images/moviedb.jpg';
import bgdcalculator from '../images/budget-calculator-react-hook.jpg';
import tetrisjs from '../images/tetris-js.png';
import bookstore from '../images/bookstore.png';
import github from '../images/github.png';

const projects = [
  {
    id: uuidv4(),
    name: 'MERN Stack Map Pin App',
    desc:
      'React.js - Node.js Full-stack map pinning travel app using React hooks, Mapbox, Express, and MongoDB. In this application, you can pin your location and share your reviews with other users.',
    img: mernstackmapapp,
    link:
      'https://github.com/BatuhanSatilmis/MERN-Fullstack-Travel-Map-Application',
  },
  {
    id: uuidv4(),
    name: 'Movie DB React Web App',
    desc:
      'It is inspired by the Imdb website.An application that informs users about the movies they want with a responsive design using themoviedb api.',
    img: moviedb,
    link: 'https://github.com/BatuhanSatilmis/movie_app_themoviedb_api',
  },
  {
    id: uuidv4(),
    name: 'Book Store Mern Stack App',
    desc:
      'It is a Full Stack book finder/changer application designed with a simple UI. Used Technologies : React, Node, Express, MongoDB',
    img: bookstore,
    link:
      'https://github.com/BatuhanSatilmis/Book-Store-MernStack/blob/master/README.md',
  },
  {
    id: uuidv4(),
    name: 'Millionare App',
    desc:
      'Who wants to be a millionaire? It is a simple react project inspired by the tv program named. in this application,  if you answer the questions correctly you earn money and you go towards the big prize.',
    img: millionare,
    link: 'https://github.com/BatuhanSatilmis/millionare-react-app-',
  },
  {
    id: uuidv4(),
    name: 'Budget Calculator React App',
    desc:
      'A todolist-like application for understanding hook, state, props and many more react features. In this application, you can update and add products with their names and prices.',
    img: bgdcalculator,
    link: 'https://github.com/BatuhanSatilmis/react-hooks-budged-calculator',
  },
  {
    id: uuidv4(),
    name: 'E-commerce with React',
    desc:
      'Responsive E-commerce Application using react. A simple project with a user-friendly interface like many e-commerce sites.',
    img: eCommerce,
    link: 'https://github.com/BatuhanSatilmis/e-commerice-website-in-React',
  },
  {
    id: uuidv4(),
    name: 'Tetris.js',
    desc: 'Simple and useful tetris app written in vanilla javascript only.',
    img: tetrisjs,
    link: 'https://github.com/BatuhanSatilmis/tetris-game-js',
  },

  {
    id: uuidv4(),
    name: 'Github',
    desc:
      'If you want to learn more about my projects, you can check out my Github account.',
    img: github,
    link: 'https://github.com/BatuhanSatilmis?tab=repositories',
  },
];

export default projects;
