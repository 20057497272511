import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion/dist/framer-motion';
import { useInView } from 'react-intersection-observer';
import Button from './Button';
import PText from './PText';
import SectionTitle from './SectionTitle';

// import AboutImg from '../assets/images/code-coding.gif';

const AboutSectionStyles = styled.div`
  background-color: black;

  padding: 10rem 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .aboutSection__left {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .section-title {
    text-align: left;
  }
  .para {
    margin-top: 2rem;
    margin-left: 0;
  }
  .aboutSection__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin-top: 2rem;
  }
  .aboutImg {
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 950px) {
    .aboutSection__left {
      text-align: center;
      align-content: space-around;
    }
    .aboutSection__right {
      flex: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      text-align: center;
    }
    .aboutSection__left {
      width: 100%;
      text-align: center;
    }

    .section-title {
      text-align: center;
    }
    .para {
      margin: 0 auto;
      margin-top: 2rem;
    }
    .aboutSection__buttons {
      flex-direction: column;
      gap: 0rem;
      .button-wrapper,
      a {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export default function AboutSection() {
  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    // console.log('use effect hook , inView = ', inView);
    if (inView) {
      animation.start({
        y: 0,
        transition: {
          type: 'srping',
          duration: 1,
          stiffness: 2000,
          bounce: 0.5,
        },
      });
    }
    if (!inView) {
      animation.start({ y: '-10vw' });
    }
  });

  return (
    <AboutSectionStyles>
      <div ref={ref} className="container">
        <div className="aboutSection__left">
          <SectionTitle subheading="" heading="About Me" />
          <motion.div animate={animation}>
            <PText>
              I am a Front End developer from Istanbul. I aim to create
              professional websites.In addition, Back-end and Mobile Application
              development concepts are also of interest to me.
            </PText>
          </motion.div>
          <div className="aboutSection__buttons">
            <Button btnText="Works" btnLink="/projects" />
            <Button btnText="Read More" btnLink="/about" outline />
          </div>
        </div>
        {/* <div className="aboutSection__right">
          { <img className="aboutImg" src={AboutImg} alt="Img" /> }
        </div> */}
      </div>
    </AboutSectionStyles>
  );
}
