import React, { useState } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import {
  AiFillLinkedin,
  AiFillGithub,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillInstagram,
} from 'react-icons/ai';

import HeroImg from '../assets/images/hero.png';
import Button from './Button';
import SocialMediaArrow from '../assets/images/social-media-arrow.svg';
import ScrollDownArrow from '../assets/images/scroll-down-arrow.svg';
import PText from './PText';
import bg from '../assets/images/bg2.mp4';

const HeroStyles = styled.div`
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    background: rgba(0, 0, 0);
  }

  @media (-webkit-video-playable-inline) {
    video {
      display: none;
      display: initial;
    }
  }

  .hero {
    height: 100vh;
    min-height: 1000px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Inconsolata';
  }
  .hero__heading {
    font-size: 2rem;
    margin-top: -7rem;
    position: relative;
    font-size: 4.2rem;

    .hero__name {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      font-size: 7rem;
      color: #fff;
    }
  }

  .hero__img {
    position: relative;
    max-width: 500px;
    width: 100%;
    height: 500px;
    margin: 0 auto;
    overflow: hidden;
  }
  .hero__img img {
    position: relative;
    max-width: 500px;
    width: 100%;
    height: 500px;
    margin: 0 auto;
    z-index: 3;
    margin-top: -10px;
    border-radius: 120px;
  }
  .hero__img::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#ffff, #ffff);
    animation: rotate 12s linear infinite;
  }
  .hero__img::after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 2);
    inset: 2px;
    border-radius: 6px;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(180deg);
    }
  }
  .hero__info {
    position: relative;
    margin-top: 1rem;
  }
  .hero__social,
  .hero__scrollDown {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: absolute;
    bottom: 20px;
    width: 50px;
  }
  .hero__social {
    left: 50px;
  }
  .hero__scrollDown {
    right: 50px;
  }

  .hero__social__indicator,
  .hero__scrollDown {
    width: 50px;
    p {
      font-size: 1.6rem;
      transform: translateY(-70px) rotate(90deg);
      letter-spacing: 0.7rem;
      text-transform: uppercase;
    }
    img {
      max-height: 45px;
      width: 16px;
      margin: 0 auto;
      object-fit: contain;
    }
  }
  .hero__scrollDown {
    img {
      max-height: 70px;
    }
  }

  .hero__social__text {
    ul {
      li {
        margin-bottom: 1rem;
        a {
          display: inline-block;
          font-size: 1.6rem;
          transform: rotate(-90deg);
          letter-spacing: 5px;
          margin-bottom: 2rem;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .hero {
      min-height: 750px;
    }
    .hero__heading {
      font-size: 2.3rem;
      margin-top: -11rem;
      .hero__name {
        font-size: 3rem;
      }
    }
    .hero__img {
      height: 270px;
      width: 270px;
    }
    .hero__img img {
      height: 270px;
      width: 270px;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
    }
    .hero__info {
      margin-top: 2.7rem;
    }
    .hero__social {
      left: 0px;

      width: 20px;
      .hero__social__indicator {
        width: 20px;
        p {
          font-size: 1.2rem;
        }
        img {
          max-height: 22px;
        }
      }
      .hero__social__text {
        ul {
          li {
            a {
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
    .hero__scrollDown {
      right: 0;
      width: 20px;
      gap: 1rem;
      p {
        font-size: 1.3rem;
      }
    }
  }
`;

export default function HeroSection() {
  const [state] = useState({
    title: '',
    titleTwo: "I'm",
    titleThree: 'Batuhan S.',
  });

  return (
    <HeroStyles>
      <div className="hero">
        <video src={bg} autoPlay loop muted playsInline preload />

        <div className="container">
          <h1 className="hero__heading">
            <span className="hero__name">
              <div className="title">{state.title}</div>
              <div className="titleTwo">{state.title}</div>
              <div className="titleThree">{state.title}</div>
            </span>
            <div className="text">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 40,
                  strings: [
                    'Batuhan Satilmis',
                    'Computer Engineer',
                    'Frontend Developer',
                    'Software Developer',
                    'Web Developer',
                  ],
                }}
              />
            </div>
          </h1>
          <div className="hero__img">
            <img src={HeroImg} alt="" />
          </div>
          <div className="hero__info">
            <PText>I am currently working as a Front-End developer.</PText>
            <Button btnText="see my works" btnLink="/projects" />
          </div>
          <div className="hero__social">
            <div className="hero__social__indicator">
              <p>Follow</p>
              <img src={SocialMediaArrow} alt="icon" />
            </div>
            <div className="hero__social__text">
              <ul>
                <li>
                  <a
                    href="http://linkedin.com/in/batuhan-satilmis-29063814a"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillLinkedin size={50} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/BatuhanSatilmis"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillGithub size={50} />
                  </a>
                </li>
                <li>
                  <a
                    href="http://facebook.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillFacebook size={50} />
                  </a>
                </li>
                <li>
                  <a
                    href="http://twitter.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillTwitterSquare size={50} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/batuhanstlmis/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillInstagram size={50} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="hero__scrollDown">
            <p>Scroll</p>
            <img src={ScrollDownArrow} alt="ScrollDown Arrow" />
          </div>
        </div>
      </div>
    </HeroStyles>
  );
}
